$main-color: rgb(58, 79, 207);;
$main-color-active: rgb(67, 111, 231);

$danger-color: #830000;
$danger-color-active: #f34343;

.button {
	
	border: 1px solid $main-color;
	border-radius: 4px;
	background: $main-color;
	color: white;
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
}

.button:focus {
	outline: none;
}

.button:hover,
.button:active {
	background: $main-color-active;
	border-color: $main-color-active;
}

.button--inverse {
	background: transparent;
	color: white;
}

.button--inverse:hover,
.button--inverse:active {
	color: white;
	background: $main-color;
}

.button--danger {
	background: $danger-color;
	border-color: $danger-color;
}

.button--danger:hover,
.button--danger:active {
	background: $danger-color-active;
	border-color: $danger-color-active;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
	background: #ccc;
	color: #979797;
	border-color: #ccc;
	cursor: not-allowed;
}

.button--small {
	font-size: 0.8rem;
}

.button--big {
	font-size: 1.5rem;
}