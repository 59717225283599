.page-container {
	min-width: 82.5%;
	max-width: 82.5%;
	height: 77vh;
	background-color: #4d4d4d;
	float: right;

	&.is-luts {
		background-color: #1a1a1a;
	}
}

.home .page-container {
	// height: 86.5vh;
	height: 88.5vh;
}