.home-page {
	width: 100%;
	height: calc(100% - 55px);
	float: right;

	background: linear-gradient(#024079, #02002d);

	overflow: auto;
}

.home .home-page {
	// padding-top: 50px;
	height: 100%;
}