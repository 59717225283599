.line{
    margin-top: 10px;
    margin-bottom: 10px;
    
}

p{
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: pre-line;
}

li{
    cursor: pointer;
}
