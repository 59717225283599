.collection-preview-card {
	background-color: rgb(43, 39, 39);
	width: 400px;
	height: 175px;
	border-radius: 15px;
	padding: 12px;
	font-weight: 500;
	box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);

	.title {
		display: block;
		font-size: 1.75rem;
		margin-bottom: 25px;
		position: relative;
		padding-left: 65px;

		img {
			position: absolute;
			top: -38px;
			left: -30px;
			width: 125px;
			height: 125px;
		}
	}

	.text-content {
		display: block;
		font-size: 1.1rem;
	}
}

.collection-preview-card.one {
	position: absolute;
	top: calc(5%);
	left: calc(-5.5%);
}

.collection-preview-card.two {
	position: absolute;
	top: calc(5%);
	right: calc(-5.5%);
}

.collection-preview-card.three {
	position: absolute;
	bottom: calc(5%);
	right: calc(-5.5%);
}

.collection-preview-card.four {
	position: absolute;
	bottom: calc(5%);
	left: calc(-5.5%);
}
