.sound-card {
	background: #2b2b2b;
	width: 95%;
	margin: 0 auto;
	border-radius: 5px;
	margin-bottom: 4px;

	.asset-image img {
		width: 75px;
		height: 75px;
		padding: 5px 3px;
		border: 1px solid transparent;
		border-radius: 5px;
		vertical-align: middle;
	}

	.asset-status:hover {
		cursor: pointer;
	}

	.asset-soundwave img {
		width: 100%;
		// VICTOR - come back and fix this
		max-height: 75px;
	}

	.asset-keywords span {
		font-size: .95rem;
	}

	.play-status i {
		font-size: 3rem;
	}

	audio {
		display: none;
	}
}