@import url("https://use.typekit.net/rhk0zxs.css");
.poppins-heading {
	font-family: poppins, sans-serif !important;
}

.beta,
.my-account {
	font-family: "proxima-nova", sans-serif;
	width: 100%;
	height: 100%;
	background: #464646;
	// h1{
	// 	font-size: 4vw;
	// }
	// h2{
	// 	font-size: 3vw;
	// }
	// h3{
	// 	font-size: 2vw;
	// }
	// p{
	// 	font-size: 1vw;
	// }
	h1 {
		// font-size: 4vw;
		font-size: max(70px, 10px);
	}
	h2 {
		// font-size: 3vw;
		// font-size: max(2vw, 30px);
		font-size: max(50px, 10px);
	}
	h3 {
		// font-size: 2vw;
		// font-size: max(1vw, 20px);
		font-size: max(30px, 10px);
	}
	p {
		// font-size: 1vw;
		// font-size: max(0.5vw, 10px);
		font-size: max(20px, 10px);
		// line-height: 150%;
	}

	// Section 1 - Header Component
	.header {
		position: absolute;
		top: 4%;
		height: 8vh;
		display: flex;
		justify-content: space-between;
		// padding-left: 5vw;
		// padding-right: 5vw;
		// padding-top: 2vh;
		max-width: 1700px;
		left: 50%;
		transform: translate(-50%, -50%);
		// text-align: center;
		// justify-self: center;
		// align-items: center;
		// justify-content: center;

		// margin: auto;
		margin-top: 3em;
		// font-size: 1vw;
		.header-home {
			min-width: auto;

			.main-logo {
				max-height: 35px;
				height: auto;
				vertical-align: middle;
			}

			a {
				position:initial;
				

				&:hover {
					text-decoration: none;
					// color: rgb(124, 124, 124);
				}
			}
		}
		.header-log-in {
			// min-width: 10%;
			// height: 100%;
			// position: absolute;
			// right: 5%;
			font-weight: bold;
			margin-right: 5%;
			font-size: max(20px, 10px);
			span {
				display: inline-block;
				position: relative;
				top: 50%;
				right: 0%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				text-align: center;
				// color: rgb(124, 124, 124);
				color: white;
				padding: 10px;

				&:hover {
					color: white;
					cursor: pointer;
				}
			}
		}

		.header-my-account {
			min-width: 10%;
			height: 100%;
			position: absolute;
			right: 0%;

			span {
				display: inline;
				position: relative;
				top: 50%;
				right: 0%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				text-align: center;
				color: rgb(124, 124, 124);
				padding: 10px;

				&:hover {
					color: white;
					cursor: pointer;
				}
			}
		}

		.header-start {
			min-width: 10%;
			height: 100%;
			position: absolute;
			right: 0%;
		
			.start-free {
				position: relative;
				top: 50%;
				right: 0%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}

	.main-content {
		margin-top: -8vh;
		width: 100%;
		// height: calc(92vh - 55px);
		height: calc(100vh);
		// Header: 8vh, Footer: 55px
		position: absolute;
		top: calc(8vh);
		overflow: auto;
		overflow-x: hidden;
		
		// Section 4 - Directly in Adobe Premiere Funnel
		.directly-funnel {
			// background: radial-gradient(rgb(128, 68, 128), rgb(24, 21, 21));
			// background: rgb(255, 0, 44);
			// background: linear-gradient(0deg, #d42a4e 0%, #d27d28 100%);
			// width: 100%;
			// max-width: 1200px;
			// margin: 0 auto;
			// height: 100%;
			padding-bottom: 1px;
			.section-title {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				// font-size: 3rem;
				padding: 150px 0px 50px;
				
			}

			.content-wrapper {
				h2{
					text-align: center;
					// font-size: 2.5vw;
				}
				p{
					text-align: center;
					// font-size: 1vw;
					font-weight: 100;
				}
				// max-width: 80vw;
				max-width: 1200px;
				width: 100%;
				// width: 50%;
				// height: 1250px;
				height: 100%;
				margin: 0 auto;
				position: relative;
				// padding-bottom: 100px;
				padding-top: 15vh;
				hr{
					background-color: grey;
					padding: 1px;
					border: none;
					margin-top: 10vh;
					margin-bottom: 10vh;
				}
				.video-wrapper {
					display: grid;
					grid-template-columns: 50% 45%;
					margin: 0 auto;
					gap: 2vw;
					margin-top: 12vh;
					width: 100%;
					h3{
						// font-size: 2vw;
						text-align: left;
						margin-bottom: 1vh;
						font-weight: bold;
						font-size: max(30px, 10px);
					}
					p{
						font-weight: 100;
						margin-bottom: 0.5vh;
						text-align: left;
					}
					video{
						width: 100%;
						border-radius: 2rem;
					}
				}
			}



			width: 100%;
			margin: 0 auto;
			background: #1a1a1a;
			background: linear-gradient(0deg, black 0%, #1a1a1a 100%);
			position: relative;
		}
		// Section 5 - Constantly Updating & Evolving Funnel
		.update-evolve-funnel {
			background-color: black;
			.funnel-main{
				max-width: 1200px;
				margin: auto;
				// margin-top: 10vh;
				padding-top: 7vh;
			}
			.top-section {
				margin: 0 auto;
				width: 90%;
				position: relative;
				padding: 35px;
				padding-bottom: 10px;
				// padding-top: 150px;
				.icon {
					position: absolute;
					top: 25%;
					left: calc(50% - (155.6px / 2));

					img {
						height: 150px;
					}
				}

				.title {
					// font-size: 2.65rem;
					width: 100%;
					text-align: center;
					margin-top: 85px;
					margin-bottom: 15px;
				}

				.content {
					// width: 200%;
					text-align: center;
					// background-color: red;
					// font-size: 1.25rem;
				}
			}

			.bottom-section {
				margin: 0 auto;
				padding-bottom: 22vh;
				width: 100%;
				.get-started-btn {
						background-color: #4064c0;
						color: white;
						outline: none;
						border: none;
						cursor: pointer;
						border-radius: 0.5rem;
						padding: 10px;
						margin: auto;
						display: flex;
						width: 18%;
						text-align: center;
						justify-content: center;
						margin-top: 12vh;
						// font-weight: bold;
						z-index: 100;
						position: absolute;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: max(22px, 10px);



				}
				// hr{
				// 	background-color: #f2f2f2;
				// 	padding: 1px;
				// 	border: none;
				// 	margin-top: -2vh;
				// }
				.info-section{
					// background-color: red;
					display: grid;
					grid-template-columns: 25% 50%;
					// background-color: red;
					justify-content: center;
					gap: 5%;

					img {
							width: 100%;
						}
					div{
						h2{
							// font-size: 3vw;
						}
						h3{
							margin-top: 7vh;
						}
						p{
							margin-top: 2vh;
						}
						
					}
				}
				.assets-teaser {
					text-align: center;
					margin-top: 25px;

					video,img {
						height: auto;
						width: 80%;
					}
					margin-bottom: 15vh;
				}

				.action-btn {
					width: 100%;
					text-align: center;

					.start-free button {
						// font-size: 1.75rem;
					}
				}

				.log-in {
					padding: 20px 0px;
					text-align: center;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		// Section 6 - All In One Place Funnel
		.all-in-one-funnel {
			width: 100%;
			margin: 0 auto;
			// height: 120%;
			background-color: #1a1a1a;
			position: relative;
			// max-width: 1200px;
			margin: 0 auto;
			padding-bottom: 10vh;
			
			.comp-img {
				position: absolute;
				top: calc(-30%);
				left: 0;
				max-height: calc(111vh);
				height: auto;
			}
				.all-in-one-funnel-content{
					max-width: 1200px;
					margin: auto;
					// height: 120%;
					// width: 75vw;
					// border-top: 1px solid white;
					padding-top: 15vh;;
					// padding-bottom: 10vh;
					// background-color: red;
					h3,h2{
						text-align: center;
						// border-top: 1px solid white;
						// font-size: 2vw;
					}
					p{
						text-align: center;
					}
					.main-how-to {
						// position: block;
						// // background-color: red;
						display: grid;
						grid-template-columns: 40% 40%;
						// gap: 20px;
						justify-content: center;
						// gap: 10%;
						padding-top: 10vh;
						h3,p{
							text-align:left;
						}
						div{
							padding: 20px;
						}
						img {
							margin-top: -20px;
							// width: 100%;
							border-radius: 5%;
						}
						// background-color: red;
						// border: 2px solid red;
						// height: 100px;
				}


				.adobe-640-tag {
					padding: 30px 0px;
					margin: 0 auto;
					width: 50%;
	
					img {
						vertical-align: middle;
						height: 50px;
					}
	
					span {
						// font-size: 1.5rem;
					}
				}
	
				.title {
					margin: 0 auto;
					width: 50%;
					// font-size: 2.5rem;
					margin-bottom: 10px;
				}
				.content-one,
				.content-two {
					// font-size: 1.25rem;
					margin: 0 auto;
					width: 50%;
					padding: 10px 0px;
				}
			}
		}
		.launch-funnel{
			// max-width: 1200px;
			background-color: #333333;
			display: flex;
			
			.launch-main{
				max-width: 1200px;
				margin: auto;
							
				h2,
				p {
					text-align: center;
				}
			
				img {
					width: 60%;
					margin: auto;
					display: flex;
					margin-top: 5vh;
				}
			
				padding-top: 15vh;
				padding-bottom: 15vh;
			}

		}
		// Section 7 - Exploration Funnel
		.exploration-funnel {
			padding-top: 10vh;
			padding-bottom: 10vh;
			background-color: #1a1a19;
			.main-exploration{
				max-width: 1200px;
				margin: auto;
				h2{
					text-align: center;
					margin-bottom: 5vh;
				}
				iframe{
					display: flex;
					margin: auto;
					margin-bottom: 5vh;
				}
				p{
					text-align: center;
					// line-height: 10px;
				}
				.get-started-btn {
					background-color: #4064c0;
					color: white;
					outline: none;
					border: none;
					cursor: pointer;
					border-radius: 0.5rem;
					padding: 10px;
					margin: auto;
					display: flex;
					width: 18%;
					justify-content: center;
					margin-top: 10vh;
					font-size: max(22px, 10px);
					padding: 0.3% 2% 0.3% 2%;
					// font-weight: 100;





				}

			}
		}
		// Section - Join the community
		.join-the-community-funnel{
			padding-top: 10vh;
			padding-bottom: 10vh;
			background-color: #333333;
			.social-networks{
				h3{
					text-align: center;
					margin-top: 5vh;
				}
				p{
					text-align: center;
					margin-bottom: 5vh;
				}
				div{
					display: flex;
					justify-content: center;
					margin-top: 5vh;
					a{
						max-width: 70px;
						margin-left: 1vw;
						margin-right: 1vw;
						display: flex;
					}
					img{
						max-width: 70px;
						margin: auto;
					}
				}
			}
			.cards{

				max-width: 1600px;
				margin: auto;
				margin-top: 10vh;
				.one{
					display: flex;
					justify-content: center;
				}
				.two{
					margin-top: 10px;
					display: flex;
					justify-content: center;
					width: 100%;
				}
			}

			h2 {
				text-align: center;
			}

			.review-card{
				background-color: #131313;
				text-align: left;
				border-radius: 1rem;
				padding:1% 2% 1% 2%;
				max-width: 320px;
				margin-left: 10px;
				margin: 10px;
				// height: 150px;
				align-self: flex-start;
				overflow: auto;
				
				p{
					font-size: 11px;
					overflow: auto;
				}
				.username{
					width: 93%;
					display: grid;
					grid-template-columns: auto auto auto;
					align-items: center;
					gap: 5px;
					h3{
						font-size: 16px;
					}
					.user-pic{
						height: 40px;
						width: 40px;
						background-color: #ffffff;
						border-radius: 100%;
						display: inline-block;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
					}
					.stars{
						display: flex;

						svg {
							margin: auto;
							color: #fbb03b;
						}
					}
			  	}
				.user-profession{
					position: relative;
					// width: 50%;
					margin-left: 48px;
					margin-top: -5px;
					font-size: 13px;
				}
				
			}
			
			
		}
		// Section - requirements
		.requirements-funnel{
			text-align: center;
			background-color: #1a1a19;
			padding-top: 10vh;
			padding-bottom: 10vh;
		}
		
		// Section 8 - Get Started Funnel
		.get-started-funnel {
			width: 100%;
			margin: 0 auto;
			height: auto;
			background: white;
			margin: 0 auto;
			padding: 50px 0px 200px ;
			position: relative;
			.funnel-main{
				max-width: 1300px;
				margin: auto;
				color: black;
				text-align: center;
				display: flex;
				.one{
					text-align: left;
				}
				.two{
					text-align: left;
					margin: 0 auto;
					.notify-email-input {
							display: flex;
							margin-top: 5vh;
					
							button {
								outline: none;
								border: none;
								background-color: #0071bc;
								color: white;
								border-radius: 0.5rem;
								position: relative;
								margin-left: -15px;
								cursor: pointer;
								width: 5%;
								padding: 0.2vh;
								svg {
									vertical-align: middle;
								}
							}
							input[type="email"] {
								padding: 0.2vh;
								outline: none;
								border-top: 1px solid black;
								border-bottom: 1px solid black;
								border-top: 1px solid black;
								border-left: 1px solid black;
								border-radius: 0.5rem;
								width: 50%;
							}
						}
				}

			}
			

			.get-creative {
				margin: 0 auto;
				width: 50%;
				// font-size: 1.25rem;
				margin-bottom: 20px;
				padding-top: 25px;

				.img-div {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					padding-bottom: 15px;
				}

				img {
					max-height: 75px;
					height: auto;
				}

				div {
					text-align: center;
				}
			}

			.get-started {
				margin: 0 auto;
				width: 50%;
				text-align: center;
				// font-size: 2.5rem;
				margin-bottom: 35px;
			}

			.cta-container {
				text-align: center;

				.start-free button {
					// font-size: 1.75rem;
				}
			}

			.log-in {
				text-align: center;

				div {
					padding: 15px 0px 0px;
				}

				.log {
					&:hover {
						cursor: pointer;
					}
				}
			}

			.copyright {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				position: absolute;
				bottom: 12.5%;
			}
		}
	}

	// Section 9 - Footer Section
	.footer .footer-wrapper {
		width: unset;
	}
}

.wrapper {
	height: 100%;
	width: 100%;
}

.obscure-wrapper {
	filter: brightness(25%);
	height: 100%;
	width: 100%;
}
