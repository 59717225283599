.mobile-funnel {
	height: 100vh;
	width: 100vw;

	.section-one {

		.title-one {
			color: white;
		}

		.title-two {
			color: black;
		}

	}

	.section-two {
		background: black;
		color: white;
	}

	.section-three {
		background: gray;
		color: white;
	}
}