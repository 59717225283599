.edit-button {
	color:#c9c9c9;
	background-color: #333333;
	padding: 3px 12px;

	border: none;
	border-radius: 5px;

	position: relative;
	float: right;

	&:hover {
		cursor: pointer;
		background-color: #444444;
		transition: .25s;
	}
}
