.credit-card-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .form-group {
    text-align: left;
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }

  .authorize-image {
    width: 50%;
    display: block;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    margin-bottom: 10px;
  }

  .fontNet {
    font-family: "Minion Pro", sans-serif;
    color: rgb(254, 254, 254);
    font-weight: bold;
    font-size: larger;
  }

  button {
    background-color: #4064c0;
    color: white;
    border: none;
    width: 100%;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
}
