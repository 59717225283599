.luts-selected-card {
	display: flex;
	width: 100%;
	margin-bottom: 35px;
	// background: white;

	.image-container {
		width: 50%;
		margin: 0 auto;

		img {
			display: block;
			margin: 0 auto;
			width: 90%;
			border: 1px solid black;
			border-radius: 8px;
		}
	}

	.content-container {
		// display: ;
		width: 50%;

		.name {
			display: block;
			font-size: 1.35rem;
			font-weight: bold;
		}

		.description {
			color: aliceblue;
		}

		.action-buttons {
			margin-top: 10px;
			.luts-dropdown {
				margin-right: 15px;

				select {
					padding: 5px;
					background-color: bisque;
					border-radius: 8px;

					&:option {
						border-radius: 10px;
					}
				}
			}

			.download-button {
				margin-left: 15px;
			}
		}
	}
}