.contact-page {
    padding-top: 5%;

	.content {
		margin: 55px auto;
		padding: 55px 15px;
		width: 65%;
		background-color: #333;
		border-radius: 5px;

		form {
			margin: 0 auto;
			width: 90%;

			input {
				width: 100%;
				height: 45px;
				margin-bottom: 10px;
				padding: 5px 8px;
	
				&::placeholder {
					font-size: 1.01rem;
				}
			}

			textarea {
				width: 100%;
				height: 85px;
				margin-bottom: 10px;
				padding: 5px 8px;

				&::placeholder {
					font-size: 1.01rem;
					// padding: 5px 8px;
				}
			}

			div {
				button {
					background-color: #c3c3c3;
					border: 1px solid black;
					padding: 8px 15px;
	
					&:hover {
						cursor: pointer;
					}
				}

				span {
					margin-left: 15px;
				}
			}
		}
	}
}