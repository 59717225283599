.favorite-button {
	color: #eee;
	background-color: #393d44;

	padding: 4px 5px;
	border: none;
	border-radius: 5px;
	margin-right: 10px;

	&:hover {
		cursor: pointer;
		color: rgb(255, 255, 255);
		background-color: #464b54;
		transition: .25s;
		// border: 1px solid #393d44;
	}
}