.explore-collection {
	height: 75%;
	width: 100%;
	margin-top: 65px;
	position: relative;

	&:first-of-type {
		margin-top: 85px;
	}

	&:last-of-type {
		margin-bottom: 25px;
	}

	.title {
		position: absolute;
		left: 60px;
		top: -40px;
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 5px;
	}

	.content {
		height: 650px;
		width: 95%;
		// background: rgba(190, 190, 190, 0.65);
		// border: 1px solid black;
		border-radius: 8px;
		padding: 10px 5px;
		margin: 0 auto;

		.main {
			width: 95%;
			// background: white;
			height: 75%;
			margin: 0 auto;
			border-radius: 8px;

			.video-wrapper {
				width: 100%;
				height: 100%;
				position: relative;
				overflow: hidden;
				border-radius: 3px;

				video {
					position: absolute;
					width: 450px !important;
					height: auto;
					min-width: 100%;
					min-height: 100%;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		.secondary {
			width: 95%;
			height: 25%;
			margin: 0 auto;

			display: flex;
			align-items: center;
			justify-content: space-between;

			.preview {
				height: 100%;
				width: 100%;
				border-radius: 8px;

				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
}