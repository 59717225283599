.video-card {
	video {
		display: block;
		margin: 0 auto;
	}

	.description {
		padding: 5px;
		text-align: center;
	}
}