 @import url("https://use.typekit.net/rhk0zxs.css");
* {
	box-sizing: border-box;
}

body {
	font-family: "proxima-nova", sans-serif;
	// font-weight: 700;
	font-style: normal;
	
	margin: 0;
	padding: 0;
	
}

a {
	text-decoration: none;
	color: white;
}