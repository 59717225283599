.sidebar {
	min-width: 17.5%;
	max-width: 17.5%;
	height: 92vh;
	background: #595959;
	float: left;
	overflow: auto;

	.user-container {
		padding: 35px 25px;

		.user-details {
			width: 100%;
		}
	}
}

.sidebar-user-name,
.sidebar-user-actives {
	display: block;
	color: white;
	margin-bottom: 5px;
}

.sidebar-user-name {
	font-size: 1.25rem;
	text-decoration: underline #0081a2 3px;

	i {
		font-size: 1.15rem;
		padding-right: 5px;
		color: #00a7e0;
	}
}

.sidebar-user-actives {
	font-size: 1rem;
}

.sidebar-user-links {
	list-style-type: none;


	li {
		font-size: 1rem;
		margin-bottom: 5px;
	
		a:hover {
			cursor: pointer;
			color: #0081a2;
			// VV - fix this!
		}
	}
}


.sidebar-section {
	// display: flex;
	padding: 10px 0;
}

// VICTOR - fix this
.sidebar-section.warning {
	background-color: rgb(139, 139, 139);

	span {
		display: block;
		width: 90%;
		margin: 0 auto;

		i {
			padding-right: 8px;
			color: yellow
		}
	}
}

.sidebar-section-heading {
	color: white;
	font-weight: bold;
	font-size: 1.5rem;
	text-align: center;
}

.sidebar-section-subheading {
	color: white;
	font-weight: bold;
	font-size: 1.15rem;
	text-align: center;
}

.sidebar-section-list {
	list-style-type: none;
	padding-bottom: 10px;

	li {
		padding-top: 10px;
		padding-left: 25px;

		a.active,
		a:hover {
			color: white;
		}

	}
}