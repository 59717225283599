.collection-card {
	background-color: rgb(40, 40, 40);
	border-radius: 5px;
	position: relative;
	max-width: 325px;
	max-height: 228.5px;
	padding: 3px;
	margin: 0 auto;

	video {
		display: block;
		width: 100%;
		margin: auto;
		height: 80%;
		// width: 300px;

		&:hover {
			cursor: pointer;
		}
	}

	// VICTOR - fix this..
	img {
		display: block;
		width: 100%;
		margin: auto;
		height: 80%;
		// margin: auto;
		// // width: 98.5%;
		// width: 320px;
		// height: 178px;

		// object-fit: contain;

		transition: .5s transform ease-in;

		&:hover {
			cursor: pointer;
			opacity: 0.75;
			transform: scale(1.01);
			overflow: hidden;
		}
	}

	.asset-details {
		height: 20%;
	}
}