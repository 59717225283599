.sound-card-list {
	display: block;
	width: 95%;
	margin: 0 auto;

	.table {
		margin: auto;
		min-width: 95%;

		border-collapse: separate;
		border-spacing: 0 8px;
	}

	thead tr td {
		text-align: center;
	}

	tbody tr {
		text-align: center;
		border: 3px solid rgb(149, 149, 149);
		border-radius: 5px;
	}
}