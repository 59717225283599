body {
  background: radial-gradient(rgb(128, 68, 128), rgb(24, 21, 21));
  background: rgb(255, 0, 44);
  background: linear-gradient(0deg, #d42a4e 0%, #d27d28 100%);
 
}

.login-form {
  width: 30%;
  margin: auto;
  padding: 20px;
  display: block  ;
  text-align: center;
  margin-top: 10%;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 1);

  h2 {
    margin: 0 0 20px;
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: left;
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      label {
        font-weight: bold;
        margin-bottom: 5px;
        text-align: left;
      }
      input[type="email"],
      input[type="username"],
      input[type="password"] {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
      }
    }

    .remember-me {
      display: flex;
      align-items: center;
      label {
        margin: 0 5px 0 0;
      }
      input[type="checkbox"] {
        margin: 0;
      }
    }

    button {
      background-color: #4064c0;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .forgot-password {
    margin-top: 10px;

    a {
      color: #007bff;
    }
  }
}
