* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	font-family: 'proxima-nova';
	line-height: 1.5;
}

body {
	margin: 0;
	padding: 0;

	// font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	// 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	// sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #333333;
	color: white;
}

html, body {
	margin: 0;
	height: 100vh;
	overflow: hidden;
}



#root {
	width: 100vw;
	height: 100vh;
	overflow: scroll;
}

/* Styling the Scrollbar */

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	// background-color: #333333;
	background-color: #595959; 
	border-radius: 210px;
	border: 6px solid transparent;
	// background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #333333;
}