.footer {
	// position: fixed;
	// bottom: 0%;
	// top: 98.5%;
	// // top: 0%;
	// height: 55px;
	// // VICTOR -convert the 55px footer to vh, similar to header bar..
	// width: 100%;
	// max-width: 1720px;
	// background: black;
	// left: 50%;
	// transform: translate(-50%, -50%);
	background: black;
	
	padding: 10px;
	.footer-wrapper {
		margin: auto;
		max-width: 1200px;
		height: 100%;
		// width: 82.5%;
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
	
		.footer-link {
			padding: 2px 3px;
			align-items: center;
			font-size: .85rem;
	
			a {
				color: rgb(201, 201, 201);;
	
				&:hover {
					color: white;
				}
			}
		}

	}


}