.page-header {
	height: 5vh;
	width: calc(100% - 12.5%);
	display: block;

	margin: 5% 0% 5% 0%;
	padding: 10px;

	h1 {
		font-size: 1.75rem;
		font-weight: bold;
		position: absolute;
		left: 60px;
		// Create 1/3 underline?
	}
}