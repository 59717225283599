.asset-name {
	display: inline-block;
	padding: 8px;
	float: left;
	height: 100%;
	width: 60%;
	// text-overflow: clip;

	span {
		display: inline-block;
		width: 100%;
		font-size: .85rem;
		vertical-align: middle;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:hover {
			cursor: pointer;
		}
	}
}