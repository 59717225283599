

.subscriptions{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    //margin-top: 1em;
    margin-bottom: 50px;
    margin-left: 10px;
    font-family: "proxima-nova", sans-serif;
}

@media only screen and (min-width: 768px) {

    .subscriptions{
        
        width: 100%;
        //
        font-family: "proxima-nova", sans-serif;
    }

    .great-div{
        margin-top: 10%;
    }

    .title{
        text-align: center;
        font-size: 500px;
    }
}

.title{
    text-align: center;
    font-size: 5em;
}