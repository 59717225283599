.authentication-modal {
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-height: calc(85vh);
  max-width: 455px;
  overflow: auto;
  position: absolute;
  top: calc(42.5%);
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .head {
    background-color: #1a1a1a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: none;

    .head-title {
      display: block;
      font-weight: bold;
      padding: 12px 0px 4px 20px;
      font-size: 1.5rem;
      text-align: center;
    }

    .message {
      display: block;
      font-size: 0.925rem;
      padding-left: 20px;
      padding-bottom: 15px;
      font-weight: bold;
      text-align: center;
      color: whitesmoke;
    }

    // text-align: center;
    // color: white;
    .exit-modal {
      display: block;
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 1.35rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    //background-color: #333333;
    //color: white;
    padding: 10px 0px 25px;
    background: none;

    .selected-plan {
      .create-account-subheader {
        .title {
          padding: 15px;
          text-align: center;
          font-weight: bold;
          background: #ccc;
        }

        hr {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    form {
      height: 80%;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 1rem;

      input[type="text"],
      input[type="email"],
      input[type="password"] {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        background: whitesmoke;
      }

      .forgot-password {
        font-size: 0.85rem;
        color: white;
        padding-bottom: 5px;

        span:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    label {
      font-weight: bold;
      margin-bottom: 5px;
      text-align: left;
    }

    .button-wrapper {
      width: 100%;
      padding: 5px 0px;

      button {
        display: block;
        margin: 0 auto;
        min-width: 225px;
        background-color: #4064c0;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
      }
    }

    .account-message {
      font-size: 0.85rem;
      text-align: center;
      padding: 5px 0px;

      // .question-prompt {}

      .cta {
        padding-left: 5px;
        color: rgb(200, 200, 200);

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
