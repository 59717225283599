.header {
	margin: 0;
	padding: 0;
	display: flex;
	width: 100%;
	height: 8vh;
	background: transparent;
	// color: red;
	// border-bottom: 3px solid white;
	z-index: 1;

	.header-home {
		display: flex;
		justify-content: left;
		align-items: center;
		font-size: 1.55rem;
		font-weight: bold;
		letter-spacing: 1px;
		min-width: 60%;
		padding-left: 15px;

		a {
			color: white;

			&:hover,
			&:active {
				text-decoration: underline solid 1px;
			}
		}
	}

	.header-tutorials {
		display: flex;
		justify-content: right;
		align-items: center;

		width: 20%;
		font-weight: bold;

		i {
			font-size: 1.15rem;
			padding-right: 8px;
		}

		a {
			color: white;

			&:hover,
			&:active {
				text-decoration: underline solid 1px;
			}
		}
	}

	.header-membership {
		display: flex;
		justify-content: right;
		align-items: center;

		width: 20%;
		font-weight: bold;
		padding-right: 15px;

		i {
			font-size: 1.15rem;
			padding-right: 8px;
		}

		a {
			color: white;

			&:hover,
			&:active {
				text-decoration: underline solid 1px;
			}
		}
	}
}