.user-info-card {
	margin: 35px auto;
	width: 97.5%;
	min-height: 285px;
	max-height: 285px;
	background-color: #616161;

	border-radius: 15px;

	.primary-info {

		height: 115px;
		width: 100%;

		.circle {
			display: inline-block;
			width: 85px;
			height: 85px;
			border: 1px solid #29abe2;
			border-radius: 50%;
			background-color: #29abe2;
			margin: 15px 25px;
			vertical-align: middle;
		}

		.name {
			display: inline-block;
			font-size: 3.25rem;
			font-weight: 700;
			width: 70%;
			vertical-align: middle;
		}
	}

}

.user-input-card {
	width: 75%;
	margin: 0 auto;
	// border-bottom: solid 1 px white;

	.user-input-container {
		padding-bottom: 3px;
		border-bottom: solid 1px white;
	}

	.user-input-label {
		display: block;
	}

	.user-input {
		// padding-bottom: 5px;
	}

	.divider {
		width: 100%;
		border-bottom: solid white 1px;
	}

	hr {
		// padding-top: 5px;
		// border-bottom: solid 1 px white;
	}
}