.loading-spinner {
	display: block;
	width: calc(100% - 12.5%);
}

.one {
	margin: 0 auto;
}

.lds-dual-ring {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 350px;
	height: 350px;
	margin: 0 auto;
}

.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 100px;
	height: 100px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #00a4dc;
	border-color: #00a4dc transparent #00a4dc transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}