.collection-page {
	width: calc(100% - 17.5%);
	height: 100%;
	position: absolute;
	overflow-y: hidden;
}

.collection-focused-item {
	width: 100%;
	height: 100%;
	background-color: blue;
}