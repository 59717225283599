.thank-you-page {
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: #1a1a19;

	.header-section {
		width: 100%;
		height: auto;
		position: relative;

		.banner {
			position: absolute;
			top: 0;
			right: 0;

			img {
				object-fit: cover;
				max-height: calc(35vh);
			}
		}

		.title {
			font-size: 2rem;
			text-align: center;
			padding: 150px 0px 5px;
		}

		.text {
			text-align: center;
			padding-bottom: 35px;
		}

		.section-break {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;

			hr {
				border: 0;
				border-top: 1px double rgb(172, 172, 172);
				flex: 1;
				z-index: 1;
			}

			img {
				height: 50px;
				margin: 20px;
			}
		}
	}

	.main-section {
		width: 100%;
		height: 100%;

		.top-section {
			text-align: center;
			padding: 35px 0px 50px;
		}

		.instructions {
			width: 90%;
			margin: 0 auto;
			padding-top: 50px;

			.instruction {
				height: auto;

				.left-section,
				.right-section {
					width: 50%;
					height: 100%;
					display: inline-block;
					vertical-align: middle;

					.image-wrapper,
					.content-wrapper {
						width: 100%;
						height: 100%;

						.extension-label {
							padding-left: 35px;
							margin-bottom: 20px;
						}

						.title {
							text-align: center;
							padding-bottom: 10px;
							font-size: 2rem;

							.title-sub {
								display: block;
							}

							img {
								vertical-align: middle;
								padding-left: 4px;
								max-height: calc(5vh);
							}
						}

						.note {
							text-align: start;
							font-size: 1.05rem;
							width: 50%;
							margin: 0 auto;
							padding-bottom: 12px;
						}

						.cta-buttons {
							width: 50%;
							margin: 0 auto;

							.wrapper {
								display: flex;
								justify-content: space-around;
								align-items: center;
								padding-top: 10px;

								button {
									background-color: #9bbcff;
									padding: 5px 10px;
									border: none;
									border-radius: 10px;
									font-size: .75rem;
									min-width: 135px;

									a {
										color: black;
									}
									&:hover {
										cursor: pointer;
										background-color: #6f9fff;
									}
								}
							}
						}
					}

					.image-wrapper {
						width: 100%;
						height: 100%;
						text-align: center;
	
						img {
							object-fit: cover;
							max-height: calc(37.5vh);
						}
					}
				}
			}
		}

		.launch-adobe-premiere {
			width: 100%;
			padding-top: 100px;
			padding-bottom: 150px;
			background-color: #333333;

			.title {
				font-size: 2rem;
				width: 100%;
				text-align: center;
			}

			.note {
				width: 50%;
				margin: 0 auto;
				text-align: center;
				padding: 35px 0px;
			}

			.image-wrapper {
				width: 50%;
				margin: 0 auto;
				text-align: center;

				img {
					height: 500px;
					max-height: calc(6.5vh);
				}
			}
		}

		.activation-section  {
			width: 100%;
			padding-top: 100px;
			padding-bottom: 200px;

			.title {
				font-size: 2rem;
				width: 100%;
				text-align: center;

				img {
					padding-left: 8px;
					max-height: 25px;
					vertical-align: middle;
				}
			}

			.note {
				width: 50%;
				margin: 0 auto;
				text-align: center;
				padding: 30px 0px;
			}

			.link {
				width: 50%;
				margin: 0 auto;
				text-align: center;
				color: #0071bc;

				&:hover {
					cursor: pointer;
				}
			}
		}

		.tutorial-section  {
			width: 100%;
			height: auto;
			padding-top: 100px;
			padding-bottom: 200px;

			background-color: #333333;

			.title {
				font-size: 2rem;
				width: 100%;
				text-align: center;

				img {
					padding-left: 8px;
					max-height: 25px;
					vertical-align: middle;
				}
			}

			.note {
				width: 50%;
				margin: 0 auto;
				text-align: center;
				padding: 30px 0px;
			}

			.link {
				width: 50%;
				margin: 0 auto;
				text-align: center;
				color: #0071bc;

				&:hover {
					cursor: pointer;
				}
			}
			.video-wrapper {
				width: 80%;
				margin:  auto;
				height: 500px;
				padding-top: 50px;
				
				iframe {
					padding-right:5%;
					padding-left: 5%;
					width: 450px !important;
					height: 100%;
					min-width: 100%;
					min-height: 100%;
					
				}
	
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

// Mobile Styles
.thank-you-page.mobile {
	.header-section {
		.text {
			.confirm-account {
				padding: 5px 15px 0px;
			}

			.checkmark {
				padding: 50px 0px;

				img {
					max-height: 75px;
				}
			}

			.desktop-visit {
				padding: 0px 20px;
			}
		}
	}
}
