.subheader {
	height: 15vh;
	min-width: 82.5%;
	max-width: 82.5%;
	float: right;

	position: relative;
	text-align: center;
	color: white;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow: hidden;
	}

	.content {
		position: absolute;
		top: 25px;
		left: 265px;

		.title {
			font-size: 45px;
			font-style: italic;
		}

		// .input {
		// 	border: none;
		// 	-webkit-appearance: none;
		// 	-ms-appearance: none;
		// 	-moz-appearance: none;
		// 	appearance: none;
		// 	background: #f2f2f2;
		// 	padding: 8px;
		// 	border-radius: 10px;
		// 	width: 250px;
		// 	font-size: 14px;
		// }

		.description {
			color: rgb(222, 222, 222);
			font-size: 16px;
		}

	}
}

// Add input fitler functionality - type and show any matches for the title content
// Match only regex from the item title
// Make efficient and loop through keywords and product title