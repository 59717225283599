.sales-funnel {
	width: 100%;

	.section-one {
		background: linear-gradient(#d36334, #d43648);
		width: 100%;
		height: 1035px;
		position: relative;

		.video-wrapper {
			video {
				width: 450px !important;
				height: auto;
				min-width: 100%;
				min-height: 100%;
			}
		}

		.content-wrapper {
			position: absolute;
			width: 60%;
			bottom: 75px;
			left: 55px;

			.title {
				display: block;
				font-size: 2.25rem;
				font-weight: 500;
				margin-bottom: 10px;
			}
	
			.description {
				display: block;
				font-weight: 500;
				font-size: 1.15rem;
			}
	
			.site-image {
				display: block;
				margin: 0 auto;
				width: 85% !important;
				height: auto;
			}
		}

		.images-wrapper {
			position: absolute;
			width: 30%;
			right: 50px;
			bottom: 75px;

			.your-text-asset {
				width: 80%;
				display: block;
				margin: 0 auto;
			}

			.activate-wrapper {
				width: 100%;
				position: relative;
				text-align: center;
				padding: 15px;

				span {
					padding-right: 10px;
				}

				img {
					position: absolute;
					top: 15px;
					width: 50px;
				}
			}
		
		}

	}

	.section-two {
		background: #1a1a1a;
		width: 100%;
		position: relative;
		height: 500px;

		.image-wrapper {
			display: inline-block;
			width: 50%;
			position: absolute;
			top: -265px;
			z-index: 1;

			img {
				// position: absolute;
				// top: 0px;
				object-fit: contain;
				width: 100%;
			}
		}

		.content-wrapper {
			position: absolute;
			width: 40%;
			right: 0px;
			top: 65px;

			.title {
				display: block;
				font-size: 2.25rem;
				font-weight: 500;
				margin-bottom: 10px;
			}

			.description {
				display: block;
				font-weight: 500;
				font-size: 1.15rem;
				margin-bottom: 5px;
			}

			.logos {
				width: 175px !important;
				height: auto;
			}
		}
	}

	.section-three {
		background: linear-gradient(#c0294f, #6c1f58);
		width: 100%;
		height: 600px;
		position: relative;

		.content-wrapper {
			position: absolute;
			bottom: 100px;
			left: 55px;
			width: 50%;
			float: left;

			.title {
				display: block;
				font-size: 2.25rem;
				font-weight: 500;
				margin-bottom: 10px;
			}

			.description {
				display: block;
				font-weight: 500;
				font-size: 1.15rem;
				margin-bottom: 5px;
			}
		}

		.call-to-action {
			position: absolute;
			bottom: 85px;
			right: 55px;
			width: 50%;
			float: right;

			.video-wrapper {
				video {
					width: 250px !important;
					height: auto;
					min-width: 100%;
					min-height: 100%;
				}
			}
	
			button {
				display: block;
				margin: 0 auto;
				width: 35%;

				background: #4064c0;
				color: white;
				font-size: 1.5rem;
				border: none;
				padding: 5px 20px;
				border-radius: 10px;
	
				&:hover {
					cursor: pointer;
				}
			}

			span {
				display: block;
				text-align: center;
				margin: 10px;
			}
		}
	}
}