.checkout-modal {
	height: auto;
	width: 75%;
	
	overflow: auto;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	z-index: 5;
	border-radius: 15px;

	.head {
		background-color: #1a1a1a;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		.plan {
			display: block;
			font-weight: 700;
			padding: 12px 0px 4px 20px;
			font-size: 1.5rem;
		}

		.message {
			display: block;

			font-size: .85rem;
			padding-left: 20px;
			padding-bottom: 15px;
		}

		.exit {
			display: block;
			position: absolute;
			top: 10px;
			right: 15px;
			font-size: 1.35rem;

			&:hover {
				cursor: pointer;
			}
		}

	}

	.body {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		background-color: #1a1a1a;
		color: white;
		padding: 1em;
		.plan {
			display: block;
			font-weight: 700;
			padding: 12px 0px 4px 20px;
			font-size: 1.5rem;
		}
		
		.card-btn {
			cursor: pointer;
			border-radius: 0.5rem;
			border-color: aliceblue;
			color: white;
			background-color: rgb(143, 178, 206);
			border-width: 1px;
			border-style: solid;
			width: 75%;
			height: 10%;
			text-align: center;
			justify-content: center;
			display: block;
			margin-top: 1em;
			padding: 0;
			// font-weight: bold;
			position: relative;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: max(22px, 10px);
		
		
		}
		.card-btn-disabled {
			cursor: default;
			border-radius: 0.5rem;
			border-color: aliceblue;
			color: white;
			background-color: rgb(119, 129, 138);
			border-width: 1px;
			border-style: solid;
			width: 75%;
			height: 10%;
			text-align: center;
			justify-content: center;
			display: block;
			margin-top: 1em;
			padding: 0;
			// font-weight: bold;
			position: relative;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: max(22px, 10px);
		
		
		}


		.plan-selection {
			display: block;
			padding: 25px 0px 0px 20px;

			span {
				font-weight: 700;
				padding-right: 3px;
			}
		}

		.option-title {
			display: block;
			font-weight: 700;
			padding-left: 20px;
			margin-bottom: 15px;
		}

		.option {
			display: block;
			padding-bottom: 10px;
			padding-left: 20px;

			.radio-wrapper {
				padding: 10px;
				padding-left: 0px;

				input:hover {
					cursor: pointer;
				}
			}

			.time {
				font-weight: 700;
				padding-right: 8px;
			}
	
			.savings {
				background-color: #009446;
				border-radius: 5px;
				padding-left: 8px;
				padding-right: 8px;
			}

			.free {
				font-size: .8rem;
				position: relative;
				top: 0px;
				left: 0px;
			}

			.price {
				position: absolute;
				right: 20px;
			}
		}

		.selected-plan {
			display: block;
			padding-bottom: 10px;
			padding: 10px 20px;

			hr {
				margin: 10px 0px;
			}

			.current-price {
				.pitch,
				.rate,
				.details {
					display: block;
					text-align: center;
				}

				.pitch {
					color: #058b44;
					font-size: 1.25rem;
				}

				.rate {
					font-size: 1.5rem;
				}

				.details {
					font-size: .85rem;
					width: 85%;
					margin: 0 auto;
					padding: 5px;
				}
			}
		}

		.terms-wrapper {
			display: block;
			padding: 10px 50px 25px;
			font-size: .85rem;

			.checkbox-wrapper {
				padding: 10px;
				padding-left: 0px;
				
				input:hover {
					cursor: pointer;
				}
			}

			a {
				color: #005f9b;

				&:hover {
					cursor: pointer;
				}
			}

		}

		.select-btn {
			display: block;
			// width: 100%;
			margin: 0 auto;

			button {
				display: block;
				color: white;
				background-color: #505b79;
				padding: 10px 20px;
				border: none;
				border-radius: 15px;
				font-weight: 700;
				font-size: 1.15rem;
				margin: 0 auto;
				width: 50%;

				&:hover {
					cursor: pointer;
				}
			}
		}

		.info-wrapper {
			display: block;
			font-size: .65rem;
			padding: 20px 0px 25px;
			// margin-top: 25px;
			// margin-bottom: 25px;

			.info-1 {
				display: block;
				text-align: center;
			}

			.info-2 {
				display: block;
				text-align: center;

				a {
					color: #005f9b;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	// Save 20% #009446

	// Select & Checked Btn 
}

@media only screen and (min-width:600px){
	.checkout-modal{
		width: 30%;
	}
}
.btn-card{
	background-color: transparent;
	border:none;
}
.hr-div {
	display: block;
	width: 75%;
	text-align: center;
	justify-content: center;
	align-self: center;
	margin: auto;
	border-style: dashed;
}

.btn{
	border-radius: 30px;
	width: 100%;
	padding-bottom: 10px;
	padding: 10px 20px;
	margin: 0 auto;

}

.container{
	width: 65%;
}