.beta,
.my-account {
	.start-free {
		button {
			background-color: rgb(119, 61, 119);
			color: white;
			// background: transparent;
			font-size: 1vw;
			border: none;
			padding: 8px 25px;
			border-radius: 25px;
	
			&:hover {
				cursor: pointer;
			}
		}
	}
}
