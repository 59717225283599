.collection-navigation {
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	// height: calc(100% - 235px);
	// height: calc(100% - 215px);
	// height: 67.5%;
	height: 77%;
	width: 12.5%;
	background: #333;
	color: white;
	overflow: auto;

	&.is-luts {
		background: #1a1a1a;
		top: 35px;
	}
	
	li {
		position: relative;
		// left: 15px;
		list-style-type: none;
		padding-top: 10px;
		padding-left: 15px;
		text-align: left;
		cursor: pointer;

		&:hover {
			// VICTOR - fix this
			// border-bottom: 1px solid #00a4dc;
			// width: 20px;
			// text-decoration: underline;
			color: #00a4dc;
		}
	}

	li:last-child {
		padding-bottom: 10px;
	}
}