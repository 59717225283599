.preview-card {
	height: 125px;
	width: 125px;
	display: inline-block;

	img {
		height: 100%;
		width: 100%;
		border-radius: 10px;
	}
}