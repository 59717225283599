.luts-home {
	width: 98.5%;
	overflow: auto;
	height: 67.5%;
	float: right;
	// margin: 0 auto;

	.luts-header {
		font-weight: 700;
		font-size: 1.5rem;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.luts-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto;
		gap: 3px;
		// width: 100%;
	}
}