.authentication-modal {
	height: auto;
	width: 100%;
	margin: 0 auto;
	max-height: calc(85vh);
	max-width: 455px;
	overflow: auto;

	position: absolute;
	top: calc(42.5%);
	left: 50%;
	transform: translate(-50%, -50%);

	.head {
		background-color: #1a1a1a;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		.head-title {
			display: block;
			font-weight: 700;
			padding: 12px 0px 4px 20px;
			font-size: 1.5rem;
		}

		.message {
			display: block;
			font-size: .925rem;
			padding-left: 20px;
			padding-bottom: 15px;
		}

		// text-align: center;
		// color: white;
		.exit-modal {
			display: block;
			position: absolute;
			top: 10px;
			right: 15px;
			font-size: 1.35rem;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.body {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		background-color: #333333;
		color: white;
		padding: 10px 0px 25px;

		.selected-plan {

			.create-account-subheader {
				.title {
					padding: 15px;
					text-align: center;
					font-weight: bold;
				}

				hr {
					width: 80%;
					margin: 0 auto;
				}
			}

		}

		form {
			height: 80%;
			width: 80%;
			margin: 0 auto;
			margin-bottom: 1rem;
	
			input[type=text],
			input[type=email],
			input[type=password] {
				background-color: #666666;
			}

			.forgot-password {
				font-size: .85rem;
				color: white;
				padding-bottom: 5px;

				span:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}

		.button-wrapper {
			width: 100%;
			padding: 5px 0px;

			button {
				display: block;
				margin: 0 auto;
				min-width: 225px;
			}
		}

		.account-message {
			font-size: .85rem;
			text-align: center;
			padding: 5px 0px;

			// .question-prompt {}

			.cta {
				padding-left: 5px;
				color: rgb(200, 200, 200);

				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
}
