.luts-card {
	background-color: rgb(40, 40, 40);
	border-radius: 5px;
	position: relative;
	// width: 325px;
	// height: 185px;
	overflow: hidden;

	span {
		position: absolute;
		top: 3%;
		left: 5%;
		color: white;
		opacity: 1;
		z-index: 100;
	}

	img {
		// display: block;
		// margin: 0 auto;
		object-fit: contain;
		height: 100%;
		width: 100%;
		// padding: 1px;
		transition: .5s transform ease-in;
	}

	&:hover {
		cursor: pointer;

		img {
			opacity: 0.75;
			transform: scale(1.05);
		}
	}
}