.form-controler {
  margin: 0;
}

.form-controler div {
  display: inline-block;
  margin: 0;
}

.form-controler label {
  display: block;
}
.form-controler input,
.form-controler textarea {
  display: inline;
}

.form-controler label {
  color: white;
  margin-bottom: 0.5rem;
  font-weight: bolder;
}

.form-control {
  background-color: whitesmoke !important;
  border-style: none;
  color: black;
  &::placeholder {
    color: #999999;
    font-weight: bold;
    padding-left: 0.5em;
  }

  &::target-text {
    color: white;
  }
}

.form-control textarea:focus {
  //outline: none;
  background-color: white !important;
  border-color: rgb(190, 81, 81);
}

.form-controler--invalid label,
.form-controler--invalid p {
  color: rgb(237, 127, 127);
}

.form-controler--invalid input,
.form-controler--invalid textarea {
  border-color: rgb(237, 127, 127);
  background: #ffd1d1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control:focus {
  color: rgb(0, 0, 0);
}
