.countdown-container {
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-values {
  display: flex;
  color: white;
  gap: 0;
  font-weight: bold;
}

.big-text {
  font-size: 1.5rem;
  margin: auto;
}

.countdown-value {
  text-align: center;
  padding: 20px;
}

.countdown-value span {
  font-size: 1rem;
}

.small-text {
  font-size: 1.5rem;
  margin: auto;
}
