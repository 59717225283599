@import url("https://use.typekit.net/rhk0zxs.css");
.sales-funnel-beta {
	width: 100%;
	margin: 0 auto;
	background: rgb(255, 0, 44);
	background: linear-gradient(0deg, #d42a4e 0%, #d27d28 100%);
	padding-top: 15vh;
	font-family: "proxima-nova", sans-serif;
	h1 {
		// font-size: 4vw;
		font-size: max(70px, 10px);
		font-family: "proxima-nova", sans-serif;
	}

	h2 {
		// font-size: 3vw;
		// font-size: max(3vw, 30px);
		font-size: max(60px, 10px);
		font-family: "proxima-nova", sans-serif;
	}

	h3 {
		// font-size: 2vw;
		// font-size: max(2vw, 20px);
		font-size: max(20px, 10px);
		font-family: "proxima-nova", sans-serif;
	}

	p {
		// font-size: 1vw;
		// font-size: max(1vw, 10px);
		font-size: max(40px, 10px);
		font-family: "proxima-nova", sans-serif;
	}
	.section-one {
		// background: linear-gradient(45deg, rgb(81, 34, 173), rgb(52, 52, 137));

		// background: black;
		
		width: 100%;
		max-width: 1200px;
		// max-width: 80vw;
		margin: 0 auto;
		position: relative;

		.video-wrapper {
			width: 100%;
			margin: 0 auto;
			h1{
				// font-size: 3vw;
				text-align: center;
			}
			p{
				text-align: center;
				// font-size: 1vw;
			}
			.get-started-btn{
				text-align: center;
				display: flex;
				margin: auto;
				border: none;
				outline: none;
				background-color: #4064c0;
				border-radius: 0.5rem;
				color: white;
				padding: 0.3% 2% 0.3% 2%;
				// font-size: 1vw;
				justify-content: center;
				font-weight: 100;
				cursor: pointer;
				margin-top: 3vh;
				width: 18%;
				font-size: max(22px, 10px);
			}
			video {
				// width: 450px !important;
				// height: auto;
				// min-width: 100%;
				// min-height: 100%;
				// margin-top: 5vh;
				width: 90%;
				border-radius: 2rem;
				margin-bottom: 10vh;
				// margin: auto;
				margin: 5vh auto;
				display: block;
				box-shadow: 0 0 20px #000;
			}

			
		}
	}

	.section-two {
		width: 100%;
		height: auto;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		min-height: calc(75vh);
		// max-width: 1200px;
		max-width: 1200px;
		// max-width: 80vw;
		margin: 0 auto;
		padding-bottom: 15vh;
		// background-color: red;

		.content-wrapper {
			margin-top: 10%;
			h2{
				text-align: center;
				// font-size: 1.5vw;
				margin-bottom: 5vh;
			}
			.get-started-items {
				margin-top: 10px;
				// display: flex;
				display: grid;
				grid-template-columns: repeat(3, minmax(250px, 1fr));
				// padding: 10vw;
				// overflow: scroll;
				div {
					h3 {
						font-size: max(20px, 10px);
						// font-size: 1.5vw;
					}
					p {
						margin-top: 10px;
						font-size: 13px;
						padding-bottom: 10%;
						// background-color: #000;
						// font-size: 1vw;
						// font-weight: bold;
					}
					div {
						background-color: #333333;
						width: 40px;
						height: 40px;
						border-radius: 100%;
						box-shadow: none;
						margin-top: 2px !important;
						z-index: 100;
						margin-bottom: 10px;
						padding: 0px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						// overflow: scroll;
					}
					background-color: #1d1d1d;
					
					height: 200px;
					border-radius: 4%;
					box-shadow: 0 0 20px #000;
					margin-top: 4vh !important;
					margin: auto;
					padding: 20px 37px;
					text-align: center;
					width: 90%;
					gap: 10%;
				}
				.get-started-items-last {
					position: absolute;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
				}
			}

			// position: absolute;
			// width: 35%;
			// bottom: 37.5%;
			// left: 2.5%;
			// padding: 25px;
			// border-radius: 10px;

			// .title {
			// 	display: block;
			// 	font-size: 2.25rem;
			// 	font-weight: 500;
			// 	margin-bottom: 10px;
			// }
	
			// .description {
			// 	display: block;
			// 	font-weight: 500;
			// 	font-size: 1.15rem;
			// }
	
			// .site-image {
			// 	display: block;
			// 	margin: 0 auto;
			// 	width: 85% !important;
			// 	height: auto;
			// }
		}

		.images-wrapper {
			display: none;
		}
	}
}
