.lifetime-membership-page {
	width: 100%;
	height: 100%;
	background-color: #d45369;
}

.referrals-container {
	position: relative;
	top: 75px;
	left: 50px;

	span {
		display: block;
	}
}

.rewards-container {
	position: relative;
	top: 100px;
	left: 100px;

	.rewards-count {
		font-size: 5rem;
		font-weight: bold;
	}

	.rewards-text {
		font-size: 1.25rem;
		font-weight: 600;
	}
}

.rewards-timeline {

	.circle {
		width: 25px;
		height: 25px;
		border: 1px solid;
		border-radius: 50%;
		background-color: white;
	}

	.timeline {
		width: 75%;
		height: 3px;
		// border-bottom: solid white 3px;
		background-color: white;
		margin: 0 auto;
		position: relative;
		top: 300px;
	}

	#orb-1 {
		position: relative;
		left: 185px;
		top: 285px;
	}

	#oneMonth {
		position: relative;
		left: 160px;
		top: 225px;
		font-size: 1.1rem;
		font-weight: 400;
	}

	#oneFriend {
		position: relative;
		left: 180px;
		top: 275px;
		font-size: 1.1rem;
		font-weight: 400;

		span {
			display: block;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}

	#orb-2 {
		position: relative;
		left: 400px;
		top: 193px;
	}

	#twoMonth {
		position: relative;
		left: 380px;
		top: 130px;
		font-size: 1.1rem;
		font-weight: 400;
	}

	#twoFriend {
		position: relative;
		left: 400px;
		top: 181px;
		font-size: 1.1rem;
		font-weight: 400;

		span {
			display: block;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}

	#orb-3 {
		position: relative;
		left: 700px;
		top: 100px;
	}

	#fiveMonth {
		position: relative;
		left: 670px;
		top: 38px;
		font-size: 1.1rem;
		font-weight: 400;
	}

	#fiveFriend {
		position: relative;
		left: 695px;
		top: 85px;
		font-size: 1.1rem;
		font-weight: 400;

		span {
			display: block;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}

	#orb-4 {
		position: relative;
		left: 1000px;
		top: 5px;
	}

	#oneYear {
		position: relative;
		left: 973px;
		top: -58px;
		font-size: 1.1rem;
		font-weight: 400;
	}

	#sevenFriend {
		position: relative;
		left: 995px;
		top: -10px;
		font-size: 1.1rem;
		font-weight: 400;

		span {
			display: block;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}

	#heart {
		font-size: 3rem;
		position: relative;
		left: 1348px;
		top: -110px;
	}

	#lifetime {
		position: relative;
		left: 1340px;
		top: -195px;
		font-size: 1.1rem;
		font-weight: 400;
	}

	#tenFriend {
		position: relative;
		left: 1335px;
		top: -140px;
		font-size: 1.1rem;
		font-weight: 400;

		span {
			display: block;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}

}

.personal-container {
	position: relative;
	top: -50px;
	left: 50px;

	span {
		display: block;
	}

	#referralCount {
		font-weight: 600;
	}
}