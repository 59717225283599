.collection-card-list {
	// width: 98.5%;
	width: 87.5%;
	overflow: auto;
	// height: 67.5%;
	height: 100%;
	// margin: 0 auto;
	margin-top: 15px;

	.wrapper {
		display: flex;
		// grid-template-columns: auto;
		// grid-template-rows: auto;
		// gap: 3px;
		margin: auto;
		width: 97.5%;
	}
}