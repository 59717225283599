@import url("https://use.typekit.net/rhk0zxs.css");

.mobile {
	h1{
		font-size: max(35px, 10px);
	}
	h2{
		font-size: max(25px, 10px);
	}
	h1 {
		font-family: "proxima-nova", sans-serif;
		font-weight: 700;
		font-style: normal;
		margin-top: 5px;
	
	}
	h2 {
		font-family: "proxima-nova", sans-serif;
		font-weight: 700;
		font-style: normal;

		font-weight: 700;
		margin-bottom: 15px;
		margin-top: 5px;
	}

	h3 {
		font-size: 90%;
		font-weight: 700;
		margin-bottom: 18px;
		margin-top: 5px;
	}

	strong {
		font-weight: 700;
	}

	p {
		margin-bottom: 10px;
		white-space: pre-line;
		font-weight: 400;
		font-size: 100%;
	}

	* {
		font-weight: 100;
	}

	.getStarted-btn {
		background-color: rgba(0, 0, 0, 0.281);
		border: 1px solid white;
		border-radius: 1rem;
		color: #fff;
		display: flex;
		font-size: 100%;
		font-weight: 100;
		justify-content: center;
		margin-top: 4vh !important;
		margin: auto;
		outline: none;
		padding: 10px;
		width: 100%;


	}


	background: transparent;
	z-index: 10;

	.header {
		position: absolute;
		top: 4%;
		height: 8vh;
		display: flex;
		justify-content: space-between;
		padding-left: 5vw;
		padding-right: 5vw;
		padding-top: 2vh;

		.header-home {
			min-width: auto;

			a {
				position: initial;
			}
		}

		.header-log-in {
			padding-right: 0%;
			position: initial;

			button {
				font-size: .75rem;

			}

			span {
				color: white;
				font-weight: bold;
			}

		}

		.header-start {
			.start-free {
				display: none;
				padding-right: 0%;

				button {
					font-size: .75rem;
				}
			}
		}
	}

	// Main Content
	.main-content {
		div {
			margin-bottom: 23px;
		}

		margin-top: -8vh;
		height: calc(100vh);

		// Sales Funnel
		.sales-funnel-beta{
			padding-top: 0px;
			background: none;
			padding-bottom: 0px;
		}
		.sales-funnel-beta .section-one {
			background: #ff002c;
			background: linear-gradient(0deg, #d42a4e, #d27d28);
			height: 100%;
			padding-top: 15vh !important;
			padding: 7vw;

			.section-one-list-container {
				display: grid;
				grid-template-columns: 21vw 26vw 38vw;
				padding-left: 5vw;
				gap: 10px;

				ul {
					word-wrap: break-word;
					font-size: 12px;
					margin-left: 15px;

					li {
						width: 100%;
						font-weight: 400;
						margin-bottom: 2px;
					}
				}
			}

			.section-one-main-title {
				padding-left: 5vw;
				padding-right: 5vw;

				span {
					font-weight: 100;
					margin-top: -2px !important;
				}
			}

			.section-one-title {
				text-align: left;
				font-size: 2em;
				line-height: 1em;
				margin-bottom: 0px;
				font-weight: bold;
			}

			.section-one-title-two {
				text-align: left;
				font-size: 2em;
				line-height: 1em;
				font-weight: bold;
				margin-top: -1px;
				margin-bottom: 5px;
			}

			.get-started-btn {
				background-color: rgba(0, 0, 0, 0.281);
				border: 1px solid white;
				border-radius: 1rem;
				color: #fff;
				display: flex;
				font-size: 100%;
				font-weight: 100;
				justify-content: center;
				margin-top: 4vh !important;
				margin: auto;
				outline: none;
				padding: 10px;
				width: 100%;
			}

			.video-wrapper {
				width: 100%;
				margin-top: 30px;

				video {
					// max-width: calc(100%);
					width: 100%;
					border-radius: 1rem;
					box-shadow: 0 0 20px #000;
				}
			}
		}

		.sales-funnel-beta .section-two {
			height: auto;
			min-height: unset;
			position: unset;
			padding: 7vw;
			background: #333333;


			.section-two-first-text {
				color: white;
			}

			.section-two-second-text {
				display: flex;

				p {
					color: white;
				}

				img {
					width: 25vw;
					display: flex;
					margin: auto;
				}

			}



			.content-wrapper {
				position: unset;
				width: 100%;
				bottom: unset;
				left: unset;
				max-width: 1200px;
				padding-top: 0px;

				.title {
					font-size: 1.3rem;
				}

				.description {
					font-size: .85rem;
				}
			}


			.images-wrapper {
				position: unset;
				right: unset;
				bottom: unset;
				width: 100%;

				.your-text-asset {
					display: none;
				}

				.activate-wrapper {
					display: none;
				}
			}
		}

		// Directly In Adobe Premiere Funnel
		.directly-funnel {
			background: #171518;
			border-radius: 1rem;

			.section-title {
				font-size: 1.5rem;
				padding: 2vh 5vw 0;
				position: relative;
				text-align: left;
			}

			.content-wrapper {
				position: unset;
				height: auto;
				width: 100%;
				margin: 0 0;
				padding-top: 0px;

				.video-wrapper {
					width: 100%;
					margin-top: 0px;
					display: block;
					video {
						position: unset;
						top: unset;
						left: unset;
						min-width: 100%;
						min-height: 100%;
						transform: unset;
						border-top-right-radius: 1rem;
						border-top-left-radius: 1rem;
						border-bottom-right-radius: 2rem;
						border-bottom-left-radius: 2rem;
						object-fit: cover;
					}
				}

				.collection-preview-card {
					position: unset;
					top: unset;
					bottom: unset;
					right: unset;
					left: unset;
					width: 80%;
					margin: 15px auto;
					height: auto;

					.title {
						font-size: 1.5rem;

						img {
							width: 100px;
							height: 100px;
							top: -30px;
							left: -20px;
						}
					}

					.text-content {
						font-size: 1rem;
					}
				}
			}
		}

		// Constantly Updating & Evolving Funnel
		.update-evolve-funnel {
			background: #ff002c;
			background: linear-gradient(0deg, #d34741, #d2722d);
			margin-top: -18vh;
			padding-bottom: 1vh;
			padding-left: 4vh;
			padding-top: 30vh;
			margin-bottom: 0px;

			.assets-teaser-texts {
				div {
					display: flex;
					justify-content: space-between;

					div {
						display: block;

						h2 {
							font-size: 25px;
						}

						p {
							line-height: 18px;
							margin-bottom: -6px;
						}
					}

					img {
						height: 50vw;
						margin: auto;
						width: 50vw;
					}

				}
			}

			.top-section {
				width: 100%;
				padding-top: 5px;

				.icon {
					top: -7.5%;
				}

				.title {
					font-size: 1.5rem;
				}

				.content {
					font-size: 1rem;
				}
			}

			.bottom-section {
				width: 100%;
				padding-bottom: 50px;

				.action-btn {
					.start-free {
						margin: 35px auto 0px;

						button {
							font-size: 1.25rem;
						}
					}
				}
			}
		}

		// All In One Place Funnel
		.all-in-one-funnel {
			height: auto;
			position: unset;
			padding: 7vw;
			background: #1A1A1A;

			img {
				width: 100%;
				margin-bottom: 13px;
			}

			ul {
				list-style: none;
				padding: 0;
				margin-bottom: 46px;

				li {
					margin-top: 46px;
				}

				ul {
					li {
						margin-top: 0px;
					}

					margin-top: 0px;
					margin-bottom: 20px;
				}
			}

			.comp-img {
				display: none;
			}

			.content-container {
				width: 100%;
				position: unset;
				top: unset;
				right: unset;

				.adobe-640-tag {
					width: 100%;

					img {
						display: block;
						margin: 0 auto;
					}

					span {
						display: block;
						text-align: center;
						padding-top: 10px;
					}
				}

				.title {
					font-size: 1.5rem;
					width: 100%;
					text-align: center;
				}

				.content-one,
				.content-two {
					font-size: 1rem;
					width: 100%;
					text-align: center;
				}
			}
		}

		// Exploration Funnel
		.exploration-funnel {
			background: #ff002c;
			background: linear-gradient(0deg, #d42153, #d2762b);
			height: 550px;
			min-height: auto;
			padding: 7vw;
			position: static;
			margin-bottom: 0px;
			div {
				h2 {
					text-align: center;
				}

				video,
				iframe {
					margin-top: -1vh;
					margin-bottom: 3vh;
					width: 100%;
				}

				p {
					text-align: center;
				}
			}

			.title {
				position: unset;
				top: unset;
				font-size: 1.05rem;
				padding: 25px 0px;
			}

			.all-demos-preview {


				.demo-one,
				.demo-two,
				.demo-three {
					position: unset;
					top: unset;
					width: 90%;
					height: 100%;
					margin: 0 auto;

					video {
						min-width: 100%;
						min-height: 100%;
					}

				}
			}
		}

		// Get Started Funnel
		.get-started-funnel {
			min-height: unset;
			background-color: #1d1d1d;
			padding: 1vw 0px;

			.get-started-vid {
				display: flex;
				margin-top: 2vh;
				margin-bottom: 0px;

				video {
					width: 100%;
					border-radius: 1rem;
				}
			}

			.get-started-items {
				margin-top: -10px;

				div {

					h2 {
						font-size: 140%;
					}

					p {
						margin-top: -15px;
					}

					div {
						background-color: #333333;
						width: 30px;
						height: 30px;
						border-radius: 100rem;
						box-shadow: none;
						margin-top: 2px !important;
						z-index: 100;
						margin-bottom: 10px;
						padding: 0px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
					}

					background-color: #1d1d1d;
					border-radius: 1rem;
					box-shadow: 0 0 20px #000;
					margin-top: 4vh !important;
					margin: auto;
					padding: 13px 9vw;
					text-align: center;
					width: 80%;
				}

				.get-started-items-last {
					position: absolute;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
				}
			}

			.img-div {
				img {
					max-height: 50px;
				}
			}

			.get-started {
				font-size: 1.5rem;
				width: 100%;
			}

			.cta-container {
				margin-bottom: 50px;

				.start-free button {
					font-size: 1.25rem;
				}
			}
		}
	}

	.footer-mobile {
		width: 100vw;
		// height: 100vh;
		background-color: #1A1A1A;

		.footer-mobile-main {
			height: 90%;
			margin-top: 20vh;
			padding-top: 50px !important;
			background: rgb(255, 0, 44);
			background: linear-gradient(0deg, #d42a4e 0%, #d27d28 100%);
			padding: 7vw;
		}

		.footer-mobile-privacy-terms {
			height: 80%;
			margin-top: 40px;
			background-color: #1A1A1A;
			text-align: center;
			padding-bottom: 20px;
			margin-bottom: 0px;

			.footer-mobile-privacy-terms-payMethods {
				display: grid;
				grid-template-columns: 50px 50px 50px;
				margin: auto;
				place-items: center;
				justify-content: center;
				align-items: center;
				gap: 10px;
				margin-bottom: 50px;

				img {
					width: 100%;
				}

				.white-icon {
					background-color: white;
					border-radius: 0.2rem;
					padding: 5px;
					width: 50px;
					height: 30px;
					object-fit: contain;
				}
			}
		}

		.getStarted-btn {
			background-color: rgba(0, 0, 0, 0.281);
			border: 1px solid white;
			border-radius: 1rem;
			color: #fff;
			display: flex;
			font-size: 100%;
			font-weight: 100;
			justify-content: center;
			margin-top: -2vh !important;
			margin: auto;
			outline: none;
			padding: 10px;
			width: 100%;
		}

		.footer-mobile-info-txt {
			padding-bottom: 2vh;
			padding-top: 50px;
			text-align: center;

			div {
				display: flex;
				justify-content: center;

				a {
					display: flex;
					margin-left: 4vw;
					margin-right: 4vw;
				}

				img {
					width: 10vw;
					margin: auto;
				}
			}
		}

		.footer-mobile-freeTrial {
			text-align: center;
		}

		.footer-mobile-requirements {
			list-style: none;
			margin: 0;
			padding: 1vh 0 0;
			text-align: center;

			li {
				padding: 0;
				margin: 0;
				list-style: none;
			}
		}

	}

	// Footer
	.footer {
		display: none;
	}
}