.activate-asset {
	display: inline-block;
	padding: 8px;
	float: right;
	height: 100%;

	.title {
		padding-right: 8px;
		font-size: .85rem;
		vertical-align: middle;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 50px;
		height: 25px;
		vertical-align: middle;

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	}
	
	.slider:before {
		position: absolute;
		content: '';
		height: 25px;
		width: 25px;
		left: 0px;
		bottom: 0px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: #00AB66;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #00AB66;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}