.my-account {
	background-color: #0a0919;
	overflow: auto;
	position: absolute;
		overflow: auto;
		overflow-x: hidden;
	
	.info-section {
		width: 100vw;
		// height: calc(100vh - 8vh - 55px);
		height: 120%;
		position: absolute;
		top: 8vh;

		.info-content {
			width: 50%;
			margin: 0 auto;

			.intro {
				padding-top: 35px;
				.main-text {
					font-size: 1.75rem;
					padding: 20px 0px;
				}

				.access-download {
					padding: 0px 0px 25px;
		
					span {
						&:hover {
							cursor: pointer;
							color: rgb(172, 172, 172);
						}
					}
				}

				.sub-text {
					padding: 0px 0px 25px;
				}

				hr {
					border: 0;
					border-top: 1px double rgb(172, 172, 172);
					flex: 1;
					z-index: 1;
				}
			}

			.main {
				.main-text {
					font-size: 1.25rem;
					padding: 20px 0px;
				}

				.sub-text {
					padding: 0px 0px 20px;
				}

				.input-wrapper {
					padding: 15px 0px;

					.password-reset {

						button {
							padding: 3px 5px;
							border-radius: 5px;
							min-width: 150px;

							&:hover {
								cursor: pointer;
							}
						}
					}

					span {
						color: #c3c3c3
					}
				}

				.edit-form {

					.left-section,
					.right-section {
						width: 50%;
						display: inline-block;

						input, label {
							display: block;
						}

						label {
							padding: 10px 0px;
						}

						input {
							width: 65%;
							padding: 8px;
							background-color: #0a0919;
							color: white;
							border: 1px solid rgb(172, 172, 172);
						}

						::placeholder {
							color:  white;
						}
					}
				}

				.action-buttons {
					width: 100%;
					margin: 25px 0;

					.btns-wrapper {
						width: 20%;
						display: flex;
						justify-content: space-between;
						align-items: end;

						button {
							border: 1px solid rgb(172, 172, 172);
							padding: 3px 5px;
							min-width: 100px;
						}
	
						.discard-btn {
							background-color: #0a0919;
							color: rgb(172, 172, 172);
						}
	
						.update-btn {
							background-color: rgb(172, 172, 172);
							color: #0a0919;
						}
					}
				}
			}
		}

		.cancel-content {
			width: 50%;
			margin: 0 auto;
			padding: 20px 0px;
			scroll-behavior: smooth;
			

			button {
				padding: 3px 5px;
				border-radius: 5px;
				min-width: 150px;
	
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.my-account.mobile {
	.info-content {
		width: 75%;
	}

	.cancel-content {
		width: 75%;
	}
}

.footer .footer-wrapper {
	width: unset;
}
