.luts-selected {

	.luts-selected-header {
		font-size: 2rem;
		margin: 10px 15px;
		font-weight: bold;
	}

	.luts-selected-wrapper {
		width: 100%;
	}
}