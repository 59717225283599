.terms-service-page {
	overflow: auto;
	position: absolute;
		overflow: auto;
		overflow-x: hidden;
    padding-top: 5%;
	
	.content {
		margin: 55px auto;
		padding: 55px 15px;
		width: 65%;
		background-color: #333;
		border-radius: 5px;
		text-align: justify;
		overflow: scroll;
		


			input {
				width: 100%;
				height: 45px;
				margin-bottom: 10px;
				padding: 5px 8px;
	
				&::placeholder {
					font-size: 1.01rem;
				}
			}

			textarea {
				width: 100%;
				height: 85px;
				margin-bottom: 10px;
				padding: 5px 8px;

				&::placeholder {
					font-size: 1.01rem;
					// padding: 5px 8px;
				}
			}

			
		
	}
}