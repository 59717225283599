.asset-card {
	width: 150px;
	display: inline-block;
	margin: 0px 10px;

	.card-wrapper {
		cursor: pointer;
		height: 150px;
		background-color: black;
		border-radius: 3px;
	
		.card-img {
			position: absolute;
			width: 140px;
			height: 140px;
			margin: 5px;
			background-color: red;
			border-radius: 10px;


			.video-thumbnail {
				display: inline-block;
				// cursor: pointer;
				margin: 30px;
				vertical-align: middle;
			
				i {
				// &:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					// content: '\f04b';
					font-family: FontAwesome;
					font-size: 100px;
					color: #fff;
					opacity: .8;
					text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
				}

				&:hover:before {
					color: #eee;
				}
			}
		}
	}

	.audio-info {
		width: 100%;
		margin: 0 auto;
	
		.audio-name {
			display: block;
			text-align: center;
			padding: 3px 0px;

		}
	}

}